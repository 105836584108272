<template>
  <div class="col-auto">
    <div
      class="status-color"
      v-bind:class="{
        green: item === 1,
        red: item === 2,
        yellow: item === 3,
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ProductFirstRow",
  props: {
    item: Number,
  },
};
</script>
